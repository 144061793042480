import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Moment } from "moment";

export interface getHourAvgHistorical_item {
  id: number;
  dev_id: string;
  date: string;
  hour: number;
  so2_min: string;
  so2_avg: string;
  so2_max: string;
  no2_min: string;
  no2_avg: string;
  no2_max: string;
  pm1_min: string;
  pm1_avg: string;
  pm1_max: string;
  pm25_min: string;
  pm25_avg: string;
  pm25_max: string;
  pm10_min: string;
  pm10_avg: string;
  pm10_max: string;
  int_temp_min: string;
  int_temp_avg: string;
  int_temp_max: string;
  int_humi_min: string;
  int_humi_avg: string;
  int_humi_max: string;
  ext_temp_min: string;
  ext_temp_avg: string;
  ext_temp_max: string;
  ext_humi_min: string;
  ext_humi_avg: string;
  ext_humi_max: string;
  pm_temp_min: string;
  pm_temp_avg: string;
  pm_temp_max: string;
  pm_humi_min: string;
  pm_humi_avg: string;
  pm_humi_max: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface getHourAvgHistorical_Returns {
  status: string;
  total_data: number;
  data: getHourAvgHistorical_item[];
}

export const getHourAvgHistorical = async (
  loc_id: number,
  start_datetime: Moment,
  end_datetime: Moment
): Promise<AxiosResponse<getHourAvgHistorical_Returns>> => {
  const config: AxiosRequestConfig = {
    method: "get",
    url: `https://monster1.distronix.in:1443/v1.0/sens/real/get_hour_avg?location_id=${loc_id}&start_date_hour=${start_datetime.format(
      "YYYY-MM-DD HH:mm:ss"
    )}&end_date_hour=${end_datetime.format("YYYY-MM-DD HH:mm:ss")}`,
  };
  const result: AxiosResponse<getHourAvgHistorical_Returns> = await axios(
    config
  );
  return result;
};
