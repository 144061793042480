import { getDeviceList_returns_data_item } from "../getDeviceList";

export const HardCodedDevices: getDeviceList_returns_data_item[] = [
  {
    lat: "22.5391080",
    location: "S.M.P.K Netaji Subhas Dock",
    long: "88.3017840",
    dev_id: "520ae9f888f1b6dd0e4e421a2278a566", // "b433e6aa0de9bef1bcff3ab6abc8f0cb",
    loc_id: 220,
    district: "Kolkata",
    aqi: "165",
  },
  {
    lat: "22.5401417",
    location: "Kolkata Port Trust (KPD-1)", // Himadri AQMS 2
    long: "88.3166933",
    dev_id: "55797fae67ce0b191a7ecd3744ef3708", // "0b44f0afd06696ad337a8d5338f1dcdc",
    loc_id: 217,
    district: "Kolkata",
    aqi: "206",
  },
];
