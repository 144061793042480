import React, { useEffect, useRef } from "react";
import GoogleMapReact from "google-map-react";
import googleMapReact from "google-map-react";

import { RootState } from "../../app/store";
import { useSelector } from "react-redux";

export const GoogleMapBox: React.FC<{
  options?: Partial<googleMapReact.Props>;
}> = ({ children, options }) => {
  const mapref = useRef<GoogleMapReact | null>(null);
  const { device } = useSelector((root: RootState) => root.input);

  const PUB_KEY = "AIzaSyC6IjsKHi8RLOzm7oGGS9vw0uYQcs87nYA";
  const DEV_KEY = "AIzaSyAut8wMgiwUlNdnp0BGlpbG4Jc0umurpM0";
  return (
    <div className="w-5/12 max-h-full">
      <GoogleMapReact
        bootstrapURLKeys={{ key: PUB_KEY }}
        defaultCenter={{ lat: 22.56, lng: 88.47 }}
        // zoom={11}
        defaultZoom={14}
        center={
          device !== null
            ? { lat: parseFloat(device.lat), lng: parseFloat(device.long) }
            : { lat: 22.56, lng: 88.47 }
        }
        zoom={14}
        resetBoundsOnResize={true}
        ref={(r) => (mapref.current = r)}
        style={{
          height: "100%",
        }}
        // {...options}
      >
        {children}
      </GoogleMapReact>
    </div>
  );
};

export const GoogleMapBoxMemoized = React.memo(GoogleMapBox);
