import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { reset } from "../../pages/user/UserSlice";

export const routeNames: { path: string; name: string }[] = [
  {
    path: "/daily",
    name: "Today's Data",
  },
  {
    path: "/hourly",
    name: "Hourly Historic Data",
  },
  // {
  //   path: "/history",
  //   name: "Historic Data",
  // },
];

export const Header: React.FC<{}> = () => {
  const { state, username } = useAppSelector((s) => s.user);
  const location = useLocation();
  const dispatch = useAppDispatch();
  return (
    <div className="flex w-full flex-col md:flex-row justify-between items-center pt-4">
      <div className="flex flex-row justify-start items-center">
        <img
          src="KPT.svg"
          alt="Himadri Speciality Chemical Limited Logo"
          className="max-w-screen-sm h-24  mx-4 p-2 rounded-md"
        />
      </div>
      <div className="p-5 text-4xl text-gray-600 text-opacity-70 font-semibold font-sans">
        Shyama Prasad Mookherjee Port ( KPT )
      </div>
      <div className="flex flex-row justify-end items-center px-4 gap-2">
        {routeNames.map((r) => (
          <Link
            to={r.path}
            className={`${
              location.pathname === r.path
                ? "bg-gray-500 text-gray-100"
                : "bg-transparent text-gray-600"
            } border-2 p-3 rounded-md shadow-md border-gray-500  font-semibold font-sans text-lg text-center`}
          >
            {r.name}
          </Link>
        ))}
        {state === "loggedIn" ? (
          <>
            <div className="text-gray-600 p-2 font-sans text-sm border-b-4 border-gray-600">
              {username}
            </div>
            <Link
              to="/signin"
              onClick={(e) => {
                dispatch(reset());
              }}
              className={`text-gray-600 border-2 border-gray-600 rounded-full p-2 font-sans text-sm hover:bg-white hover:text-blue-500 ${
                location.pathname === "/signin"
                  ? "border-b-8"
                  : "text-gray-600 bg-transparent"
              }`}
            >
              Logout
            </Link>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
